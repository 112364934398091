.MuiGrid-item {
    position: relative;
    opacity: 0;
    bottom: -80px;
    transition: all .6s ease-in-out;
}

.MuiGrid-item.inView{
    opacity: 1;
    bottom: 0;
}

.MuiGrid-item .caption{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .6s ease-in-out;
}

/* .MuiGrid-item:hover > .caption {
    visibility: visible;
    opacity: 1;
} */