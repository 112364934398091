/* body:before{
	position: fixed;
	height: 100%;
	width: 100%;
	background: #fff;
	content: "";
	transition: all 1.2s ease-in-out;
	opacity: 1;
	visibility: visible;
	z-index: 100;
	top: 0;
	left: 0;
}

body.loaded:before{
	opacity: 0;
	visibility: hidden;
} */

.App {
	text-align: center;
	background: #f6f6f6;
}

.App a{
	color: #333333;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: #333333;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
